<template>
  <div>
    <b-row>
      <b-col md="12">
        <div class="card card-custom">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Daftar Paket Soal
              </span>
            </h3>
            <div class="card-toolbar">
              <router-link
                :to="`/paket-soal/create`"
                href="#"
                class="btn btn-success font-weight-bolder font-size-sm"
              >
                Tambah Paket Soal</router-link
              >
            </div>
          </div>
          <div class="card-body pt-4">
            <b-row class="justify-content-between">
              <b-col cols="auto">
                <b-form-group>
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''"
                        >Clear</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <!--          <b-card-text>Header and footers using slots.</b-card-text>-->
            <b-table
              class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-borderless
                card-table
              "
              :filter="filter"
              :items="currentPaketList.data"
              :fields="fields"
              :busy="isLoading"
              @filtered="onFiltered"
              show-empty
              responsive
            >
              <!--              tbody-tr-class="card card-table-row card-custom card-stretch gutter-b"-->
              <template #empty="scope">
                <h6 class="text-center">Tidak ada paket ditemukan</h6>
              </template>
              <template #emptyfiltered="scope">
                <h6 class="text-center">Tidak ada paket ditemukan</h6>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #cell(index)="row">
                {{ perPage * (currentPage - 1) + (row.index + 1) }}
              </template>
              <template #row-details="row">
                <div class="text-dark-75 font-weight-bolder mb-1 font-size-lg">
                  Soal:
                </div>
                <div v-html="row.item.question"></div>
              </template>
              <template #cell(soal)="data">
                <div
                  v-html="data.item.question"
                  style="
                    max-width: 200px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                  "
                ></div>
              </template>
              <template #cell(kode_soal)="data">
                {{ data.item.quetion_code }}
              </template>
              <template #cell(tipe)="data">
                <span
                  class="
                    label label-lg label-inline
                    font-weight-bold
                    py-4
                    label-light-info
                    mr-1
                  "
                >
                  {{ data.item.type }}
                </span>
              </template>
              <template #cell(bobot)="data">
                <span
                  class="
                    label label-lg label-inline
                    font-weight-bold
                    py-4
                    label-light-info
                    mr-1
                  "
                >
                  {{ data.item.bobot }} pts
                </span>
              </template>
              <template #cell(topic)="data">
                <span
                  class="
                    label label-lg label-inline
                    font-weight-bold
                    py-4
                    label-light-info
                    mr-1
                  "
                  v-for="topik in data.item.topic"
                  :key="topik"
                >
                  {{ topik }}
                </span>
              </template>
              <template #cell(action)="data">
                <b-dropdown
                  variant="light"
                  toggle-class="custom-v-dropdown btn btn-light btn-hover-light-primary btn-icon"
                  no-caret
                  right
                  no-flip
                >
                  <template v-slot:button-content>
                    <i class="ki ki-bold-more-hor"></i>
                  </template>
                  <!--begin::Navigation-->
                  <div class="navi navi-hover min-w-md-250px">
                    <b-dropdown-text tag="div" class="navi-item">
                      <router-link
                        class="navi-link"
                        :to="`/paket-soal/${data.item._id}/edit`"
                      >
                        <span class="navi-icon">
                          <i class="flaticon2-edit"></i>
                        </span>
                        <span class="navi-text">Ubah Paket</span>
                      </router-link>
                    </b-dropdown-text>
                    <b-dropdown-text tag="div" class="navi-item">
                      <span
                        class="navi-link cursor-pointer"
                        @click="deletePaket(data.item)"
                      >
                        <span class="navi-icon">
                          <i class="flaticon2-trash"></i>
                        </span>
                        <span class="navi-text">Hapus Paket</span>
                      </span>
                    </b-dropdown-text>
                  </div>
                  <!--end::Navigation-->
                </b-dropdown>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
        <b-modal
          v-model="modalJawaban"
          centered
          scrollable
          title="Pilihan Jawaban"
          hide-footer
        >
          <div class="jawaban">
            <div
              :class="`card card-custom card-stretch border ${
                jawaban.isCorrect
                  ? 'bg-light-primary text-primary border-primary'
                  : ''
              }`"
              v-for="(jawaban, i) in selectedSoal.questions"
              :key="`${selectedSoal.id}${i}`"
            >
              <div
                v-for="(answer, i) in jawaban.answer"
                :key="`${selectedSoal.id}${i}`"
              >
                <div
                  class="
                    card-body
                    d-flex
                    flex-row
                    p-4
                    justify-content-between
                    align-items-center
                  "
                >
                  <div v-html="answer.answer"></div>
                  <div v-if="answer.point && answer.point > 0">
                    {{ answer.point }} pts
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapState } from "vuex";

import { GET_PAKET, DELETE_PAKET } from "@/core/services/store/paket.module";
import Swal from "sweetalert2";

export default {
  name: "PaketSoalList",
  data() {
    return {
      fileImport: null,
      modalImport: false,
      isLoading: false,
      selectedMapel: null,
      modalJawaban: false,
      selectedSoal: {},
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter: null,
      editorOption: {
        modules: {},
        readOnly: true,
        theme: "bubble",
      },
      fields: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "name",
          label: "Nama Paket",
          sortable: true,
        },
        {
          key: "max_scores",
          label: "Nilai Maksimal",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
      tipeSoal: {
        pg: "Pilihan Ganda",
        multiple: "Jawaban Ganda",
        truefalse: "True/False",
        essay: "Essay",
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Daftar Paket Soal" }]);
    this.getData();
  },
  watch: {
    perPage: function () {
      this.getData();
    },
    currentPage: function () {
      this.getData();
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.soal.errors,
    }),
    ...mapGetters(["currentPaketList"]),
    options() {
      var opt = [];
      this.currentMapelList.forEach((mapel) => {
        opt.push({
          text:
            mapel.kurikulum.mapel.nama_mapel +
            " - Tingkat " +
            parseInt(mapel.kurikulum.semester / 2 + 1),
          value: mapel.id_kurikulum,
        });
      });
      return opt;
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getData() {
      this.isLoading = true;
      const param = new URLSearchParams();
      param.append("limit", this.perPage);
      param.append("page", this.currentPage);
      this.$store
        .dispatch(GET_PAKET, param.toString())
        .then(() => {
          this.isLoading = false;
          this.totalRows = this.currentPaketList.total;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Soal`,
            variant: "danger",
            solid: true,
          });
        });
    },
    deletePaket(item) {
      this.$bvModal
        .msgBoxConfirm("Apakah anda yakin ingin menghapus Paket ini?", {
          title: "Konfirmasi",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Ya",
          cancelTitle: "Tidak",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.isLoading = true;
            this.$store
              .dispatch(DELETE_PAKET, item._id)
              .then((response) => {
                this.getData();
                this.$bvToast.toast(`Berhasil hapus Paket`, {
                  title: response.message,
                  variant: "success",
                  solid: true,
                });
              })
              .catch(() => {
                this.isLoading = false;
                this.$bvToast.toast(this.errors, {
                  title: `Gagal Hapus Paket`,
                  variant: "danger",
                  solid: true,
                });
              });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((err) => {
          // An error occurred
        });
    },
    viewJawaban(item) {
      // this.selectedSoal = item;
      // this.modalJawaban = true;
    },
  },
};
</script>

<style scoped>
</style>
